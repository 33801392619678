import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@adapt-design-system/core';

import Pill from '../../components/pill/Pill';

const propTypes = {
  answers: PropTypes.shape(),
  onChange: PropTypes.func,
  statement: PropTypes.shape({
    number: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultPropTypes = {
  answers: {},
  onChange: () => {},
};

const Statement = (props) => {
  const {
    answers,
    onChange,
    statement: { number, text },
  } = props;

  return (
    <Flex textAlign="center" direction="column" width="100%" {...props}>
      <Text p={[1, 2]} mb={2} variant={['h3', 'h2']} as="h2">
        {text}
      </Text>
      <Flex direction="column" my={2}>
        <Flex p={[1 / 10, 1 / 5, 1 / 2, 1]} justify="space-between" wrap="wrap">
          {[...Array(11).keys()].map((_, i) => (
            <Pill
              key={'statememt_option_' + i}
              borderRadius="100px"
              m={0}
              onClick={() => onChange(i)}
              isSelected={answers[number] === i}
            >
              <Text variant={['tiny', 'small', 'body', 'lead']}>{i}</Text>
            </Pill>
          ))}
        </Flex>
        <Flex justify="space-between">
          <Text
            m={1}
            index={0}
            color="subduedText"
            variant={['small', 'body']}
            textAlign="left"
          >
            Completely Disagree
          </Text>
          <Text m={1} index={5} color="subduedText" variant={['small', 'body']}>
            Somewhat Agree
          </Text>
          <Text
            m={1}
            index={10}
            color="subduedText"
            variant={['small', 'body']}
            textAlign="right"
          >
            Completely Agree
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

Statement.propTypes = propTypes;
Statement.defaultPropTypes = defaultPropTypes;
export default Statement;
