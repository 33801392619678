import React from 'react';
import { Box, Button, Flex, Text } from '@adapt-design-system/core';
import { navigate } from '@reach/router';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import results360Logo from 'url:../../assets/img/PNG_Results360_DIG_RED_500px.png';

const LinkButton = styled(Button)`
  background-color: transparent;
  color: ${themeGet('colors.primary')};
  border-color: ${themeGet('colors.primary')};
`;

const Nav = () => {
  const goToLandingPage = () => navigate('/');
  const leftContent = (
    <Flex align="center">
      <Box
        as="img"
        maxWidth={['100px', '150px']}
        src={results360Logo}
        onClick={goToLandingPage}
        css={{ cursor: 'pointer' }}
      />
      <Box m={1} height="100%" borderLeft="1px solid" borderColor="muted" />
      <Text
        variant={['body', 'h5', 'lead']}
        as={'h3'}
        fontWeight="bold"
        color="black"
        css={{
          cursor: 'pointer',
        }}
        onClick={goToLandingPage}
      >
        Change Power Survey
      </Text>
    </Flex>
  );
  const rightContent = (
    <LinkButton
      as="a"
      borderRadius={25}
      href="https://www.bain.com/consulting-services/change-management-results-delivery/change-power/"
      onClick={() => {}}
      target="_blank"
      size="small"
      textAlign="center"
    >
      <Text variant={['small', 'body']}>GO TO CHANGE POWER SITE</Text>
    </LinkButton>
  );
  return (
    <Flex justify="space-between" p={[2, 3]} background="white">
      {leftContent}
      {rightContent}
    </Flex>
  );
};

export default Nav;
