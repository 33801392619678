import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const statementSlice = createSlice({
  name: 'statements',
  initialState: {
    currentStatement: 0,
    answers: {},
  },
  reducers: {
    setStatement(state, { payload: newStatement }) {
      state.currentStatement = newStatement; // state mutation syntax allowed via immer library
    },
    setAnswer(state, { payload }) {
      const { statementId, answer } = payload;
      state.answers[statementId] = answer; // state mutation syntax allowed via immer library
    },
  },
});

export const useStatements = () => useSelector(({ statements }) => statements);
export const { setStatement, setAnswer } = statementSlice.actions;
export default statementSlice.reducer;
