import React from 'react';
import { Flex, Text } from '@adapt-design-system/core';

const Footer = () => (
  <Flex p={1} justify="center" bg="white">
    <Text color="muted">
      Bain Results 360 Strategy® is a registered trademark of Bain & Company, Inc.
    </Text>
  </Flex>
);

export default Footer;
