import React from 'react';
import { Box, Card, Flex, Text } from '@adapt-design-system/core';
import { useTheme } from 'emotion-theming';

const Legend = () => {
  const { colors } = useTheme();
  const legendColors = [
    ['you', colors.primary],
    ['average', 'gray'],
  ];
  return (
    <Card mt={3} p={1}>
      <Flex>
        {legendColors.map(([name, color]) => (
          <Flex
            key={'radar-chart-legend_' + name}
            justify="space-between"
            align="center"
            flexGrow={0}
            mx={1}
          >
            <Text variant="body" mr={1}>
              {name}
            </Text>
            <Box width={10} height={10} borderRadius={10} bg={color} />
          </Flex>
        ))}
      </Flex>
    </Card>
  );
};

export default Legend;
