import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Box, Flex } from '@adapt-design-system/core';

const propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

const PieChart = ({ height, width }) => {
  const { projectColors } = useTheme();
  return (
    <Flex
      css={{
        zIndex: 100,
        position: 'absolute',
        background: 'transparent',
        width: width,
        height: height,
        overflow: 'hidden',
      }}
      justify="center"
      align="center"
    >
      <Box
        css={{
          height: Math.min(height, width),
          width: Math.min(height, width),
          transform: 'rotate(-60deg)',
          opacity: '.35',
          background: `
                    conic-gradient(
                      ${projectColors.leadingBlue} 0,
                      ${projectColors.leadingBlue} 120deg,
                      ${projectColors.teamingPurple} 0,
                      ${projectColors.teamingPurple} 240deg,
                      ${projectColors.organizingGreen} 0,
                      ${projectColors.organizingGreen} 360deg
                  )
                  `,
          borderRadius: '50%',
          margin: '0',
        }}
      />
    </Flex>
  );
};

PieChart.propTypes = propTypes;

export default PieChart;
