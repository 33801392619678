import React from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import {
  Button,
  Card,
  Flex,
  LinearProgress,
  Text,
} from '@adapt-design-system/core';

import {
  useStatements,
  setStatement,
  setAnswer,
} from '../../redux/slices/statements';

import Statement from './Statement';
import statements from '../../data/statements.js';

const SurveyPage = () => {
  const { currentStatement, answers } = useStatements();
  const statement = statements[currentStatement] || statements[0];
  const totalStatements = Object.keys(statements).length;
  const answeredStatements = Object.keys(answers).length;

  const dispatch = useDispatch();
  const onStatementClick = (answer) => {
    dispatch(setAnswer({ statementId: currentStatement, answer }));
  };
  const goToPrevious = () => {
    if (currentStatement > 0) dispatch(setStatement(currentStatement - 1));
  };
  const goToNext = () => {
    if (currentStatement === totalStatements - 1) navigate('results');
    if (currentStatement < totalStatements - 1)
      dispatch(setStatement(currentStatement + 1));
  };

  return (
    <Flex
      flexGrow={1}
      direction="column"
      justify="flex-start"
      align="center"
      backgroundColor="rgba(0,0,0, .6)"
    >
      <Text
        p={[1, 2]}
        my={['5vh', '10vh']}
        variant="h1"
        as="h1"
        color="white"
        textAlign="center"
      >
        Rate your agreement with the following statements
      </Text>
      <Card minWidth={['90%', '80%', '70%']}>
        <Flex direction="column" align="center">
          <Statement
            statement={statement}
            answers={answers}
            onChange={onStatementClick}
            mb={4}
          />
          <Flex width="100%" justify="space-between" align="center">
            <Button
              mr={1}
              variant="primary"
              disabled={currentStatement === 0}
              onClick={goToPrevious}
            >
              Prev
            </Button>
            <Flex direction="column" width={['90%', '70%', '50%', '30%']}>
              <LinearProgress
                amount={(answeredStatements / totalStatements) * 100}
                my={1}
              />
              <Text variant="small" color="subduedText">
                Statement {answeredStatements} of {totalStatements} completed
              </Text>
            </Flex>
            <Button
              ml={1}
              variant="primary"
              onClick={goToNext}
              disabled={answers[currentStatement] === undefined}
            >
              {currentStatement === totalStatements - 1 ? 'Submit' : 'Next'}
            </Button>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

export default SurveyPage;
