import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';
import { Button } from '@adapt-design-system/core';

const propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
};

const defaultPropTypes = {
  isSelected: false,
  borderRadius: '25px',
};

const Pill = styled(Button)`
  color: ${({ isSelected }) =>
    themeGet(isSelected ? 'colors.white' : 'colors.primary')};
  background-color: ${({ isSelected }) =>
    themeGet(isSelected ? 'colors.primary' : 'colors.white')};
  border: 1px solid
    ${({ isSelected }) =>
      themeGet(isSelected ? 'colors.primary' : 'colors.muted')};

  &:hover {
    color: ${themeGet(`colors.white`)};
    background-color: ${themeGet('colors.primary')};
    border-color: ${themeGet('colors.primary')};
  }

  &:focus {
    box-shadow: none;
  }
`;

Pill.propTypes = propTypes;
Pill.defaultPropTypes = defaultPropTypes;

export default Pill;
