import React, { useRef, useEffect } from 'react';
import { Box } from '@adapt-design-system/core';

import { videoAccount, videoId, videoPlayerId } from './config';

const ChangePowerVideo = (props) => {
  const wrapperRef = useRef();

  useEffect(() => {
    const { current: el } = wrapperRef;
    const script = document.createElement('script');
    script.src = `https://players.brightcove.net/${videoAccount}/${videoPlayerId}_default/index.min.js`;
    script.type = 'text/javascript';
    script.async = true;
    el.appendChild(script);
  }, []);

  return (
    <Box
      as="video"
      ref={wrapperRef}
      className="video-js"
      data-video-id={videoId}
      data-account={videoAccount}
      data-player={videoPlayerId}
      data-embed="default"
      data-application-id
      controls
      playsInline
      {...props}
    />
  );
};

export default ChangePowerVideo;
