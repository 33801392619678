import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@adapt-design-system/core';
import { useTheme } from 'emotion-theming';

const propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

const colorMappings = {
  leading: 'leadingBlue',
  teaming: 'teamingPurple',
  organizing: 'organizingGreen',
};

const Labels = ({ height, width, data }) => {
  const { projectColors } = useTheme();
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        zIndex: 300,
        position: 'absolute',
        background: 'transparent',
        width: width,
        height: height,
        overflow: 'visible',
      }}
    >
      {data.map(({ name, group }, i) => {
        const rotateBy = i * (360 / data.length);
        const labelMargins = [-5, 45, 45, 45];
        const heights = labelMargins.map((x) => x + height);
        const textColor = projectColors[colorMappings[group]];
        return (
          <Text
            key={'radar-chart-names_' + name}
            css={{
              transformOrigin: 'center',
              transform: `rotate(${rotateBy}deg)`,
            }}
            position="absolute"
            height={heights}
            color={textColor}
            fontWeight="bold"
          >
            {name}
          </Text>
        );
      })}
    </Flex>
  );
};

Labels.propTypes = propTypes;

export default Labels;
