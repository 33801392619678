import React from 'react';
import { Box } from '@adapt-design-system/core';

const ChangePowerInfographic = () => {
  return (
    <Box
      as="iframe"
      src="https://e.infogram.com/9dde91f2-3d9d-4730-b019-ceb0377d81a3?src=embed"
      title="CPI Clickable case-studies"
      // maintain ratio of 1.59
      width="100%"
      height="62vw"
      scrolling="no"
      frameBorder="0"
      style={{ border: 'none' }}
      allowFullScreen="allowfullscreen"
    />
  );
};

export default ChangePowerInfographic;
