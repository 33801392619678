import React from 'react';
import { Card, Flex, Text } from '@adapt-design-system/core';

import { useStatements } from '../../redux/slices/statements';

import contacts from '../../data/contacts';
import statements from '../../data/statements';

import ContactCard from '../../components/contact-card/ContactCard';
import ChangePowerChart from '../../components/change-power-chart/ChangePowerChart';
import ChangePowerInfographic from '../../components/change-power-infographic/ChangePowerInfographic';

const createChartData = (answers, statements) => {
  const chartData = statements.map((statement) => ({
    ...statement,
    // Selected region goes here when survey results are completed in 2021
    average: statement.averages['North & South America'],
    you: answers[statement.number],
  }));
  return [...chartData.slice(1), chartData[0]];
};

const ResultsPage = () => {
  const { answers } = useStatements();
  const chartData = createChartData(answers, statements);

  return (
    <Flex direction="column" flexGrow={1} justify="center" align="center">
      <Card id="card" minWidth={'95%'} m={2} mt={4}>
        <Flex
          justify="space-between"
          direction={['column', 'column', 'row']}
          height={['unset', 'unset', '60vh']}
          mt={3}
          mb={1}
        >
          <ChangePowerChart data={chartData} />
        </Flex>
        <Flex align="center" justify="center">
          <Text variant="tiny" color="text">
            Data based on Bain Change Power Index Survey 2019 (N=1,885).
          </Text>
        </Flex>
      </Card>
      <Flex
        width="100%"
        bg="white"
        direction="column"
        justify="center"
        align="center"
        my={5}
        p={5}
      >
        <ChangePowerInfographic />
      </Flex>
      <Text variant="h1">Contact Us</Text>
      <Flex
        width="100%"
        my={5}
        direction={['column', 'row']}
        justify="space-evenly"
        flexWrap="wrap"
      >
        {contacts.map((contact, i) => (
          <ContactCard m={1} key={`contact-card_${i}`} contact={contact} />
        ))}
      </Flex>
    </Flex>
  );
};

export default ResultsPage;
