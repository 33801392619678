import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const regionSlice = createSlice({
  name: 'region',
  initialState: { selectedRegion: 'North & South America' },
  reducers: {
    setRegion(state, { payload }) {
      state.selectedRegion = payload; // state mutation syntax allowed via immer library
    },
  },
});

export const useRegion = () => useSelector(({ region }) => region);
export const { setRegion } = regionSlice.actions;
export default regionSlice.reducer;
