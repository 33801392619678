import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Flex, Text } from '@adapt-design-system/core';
import { IconEmail, IconPin } from '@adapt-design-system/icons';

const propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string.isRequired,
    headshotImg: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    email: PropTypes.string,
  }).isRequired,
};

const defaultProps = {};

const ContactCard = ({ contact, ...rest }) => {
  const { name, headshotImg, title, location, email } = contact;
  const onCardClick = (partnerName) => () => {
    const partnerPath = partnerName.toLowerCase().split(' ').join('-');
    window.open(`https://www.bain.com/our-team/${partnerPath}/`);
  };

  return (
    <Card
      {...rest}
      minWidth="350px"
      onClick={onCardClick(name)}
      css={{ cursor: 'pointer' }}
    >
      <Flex direction="column" align="center">
        <Box
          as="img"
          src={`/photos/${headshotImg}`}
          borderRadius="100px"
          width="100px"
          my={1}
        />
        <Text variant="h3">{name}</Text>
        <Text>{title}</Text>
        <Flex align="center">
          <Text color="subduedText">
            <IconPin />
            {location}
          </Text>
        </Flex>
        {email && (
          <Flex mt={3} width="100%" justify="flex-end">
            <Box as="a" href={`mailto:${email}`} color="primary">
              <IconEmail />
            </Box>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

ContactCard.propTypes = propTypes;
ContactCard.defaultProps = defaultProps;

export default ContactCard;
