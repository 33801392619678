export default [
  {
    number: 0,
    text: 'Our purpose creates a sense of belonging for all',
    group: 'leading',
    name: 'Purpose',
    averages: {
      'North & South America': 7.2,
    },
  },
  {
    number: 1,
    text:
      'As we pursue our ambition, leaders provide sufficient guidance while allowing autonomy',
    group: 'leading',
    name: 'Direction',
    averages: {
      'North & South America': 7.0,
    },
  },
  {
    number: 2,
    text:
      'Ideas spread quickly, easily and early across our company when we introduce change',
    group: 'leading',
    name: 'Connection',
    averages: {
      'North & South America': 7.0,
    },
  },
  {
    number: 3,
    text:
      'When the unexpected happens, we adjust plans and determine what to do and when',
    group: 'teaming',
    name: 'Choreography',
    averages: {
      'North & South America': 7.5,
    },
  },
  {
    number: 4,
    text: 'When we see signs of overload, we take action, prioritize and adapt',
    group: 'teaming',
    name: 'Capacity',
    averages: {
      'North & South America': 7.0,
    },
  },
  {
    number: 5,
    text:
      'We connect and assemble small changes to create big results',
    group: 'teaming',
    name: 'Scaling',
    averages: {
      'North & South America': 7.2,
    },
  },
  {
    number: 6,
    text: 'Change is an opportunity for professional growth and development',
    group: 'organizing',
    name: 'Development',
    averages: {
      'North & South America': 7.4,
    },
  },
  {
    number: 7,
    text: 'We have a can-do, problem-solving mindset',
    group: 'organizing',
    name: 'Action',
    averages: {
      'North & South America': 7.6,
    },
  },
  {
    number: 8,
    text: 'Our structure and roles flex as needed to meet changing business needs',
    group: 'organizing',
    name: 'Flexibility',
    averages: {
      'North & South America': 7.2,
    },
  },
];
