const contacts = [
  {
    name: 'David Michels',
    headshotImg: 'david-michels.jpg',
    title: 'Partner',
    location: 'Tokyo',
  },
  {
    name: 'Kevin Murphy',
    headshotImg: 'kevin-murphy.jpg',
    title: 'Expert Partner',
    location: 'Washington D.C.',
  },
  {
    name: 'Melissa Burke',
    headshotImg: 'melissa-burke.jpg',
    title: 'Practice Vice President',
    location: 'Atlanta',
    email: 'melissa.burke@bain.com',
  },
  {
    name: 'Laura Hollister',
    headshotImg: 'laura-hollister.jpg',
    title: 'Practice Director',
    location: 'Boston',
    email: 'laura.hollister@bain.com',
  },
];

export default contacts;
