import React, { useState, useEffect } from 'react';
import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import {
  AdaptThemeProvider,
  Flex,
  Text,
  Button,
} from '@adapt-design-system/core';
import { Router } from '@reach/router';

import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import LandingPage from './pages/landing-page/LandingPage';
import SurveyPage from './pages/survey-page/SurveyPage';
import ResultsPage from './pages/results-page/ResultsPage';
import globalStyles from './global-styles';
import globalFonts from './global-fonts';

import banner from 'url:./assets/img/skyline.jpg';

const projectTheme = (ancestorTheme) => ({
  ...ancestorTheme,
  projectColors: {
    teamingPurple: '#953b74',
    teamingLightPurple: '#dec1d3',
    leadingBlue: '#46647b',
    leadingLightBlue: '#a8b6c1',
    organizingGreen: '#507867',
    organizingLightGreen: '#bdccc5',
  },
});

export default function App() {
  const [shouldPageRerender, setShouldPageRerender] = useState(false);

  // Effect temporarily added as a hack to get page to rerender immediately to import the css
  // useEffect(() => {
  //   setShouldPageRerender(false);
  // });
  return (
    <AdaptThemeProvider>
      <ThemeProvider theme={projectTheme}>
        <Global styles={[globalStyles, globalFonts]} />
        {shouldPageRerender ? (
          <div />
        ) : (
          <Flex
            maxWidth="100%"
            height="100vh"
            background={`url(${banner}) no-repeat center/cover`}
            direction="column"
          >
            <Nav />
            {/* Need to target router div to add css for layout. Find css id in global-styles */}
            <Router id="reach-router">
              <LandingPage path="/" />
              <SurveyPage path="survey" />
              <ResultsPage path="results" />
            </Router>
            <Footer />
          </Flex>
        )}
      </ThemeProvider>
    </AdaptThemeProvider>
  );
}
