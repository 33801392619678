import React from 'react';
import { Button, Flex, Text } from '@adapt-design-system/core';
import { navigate } from '@reach/router';

import ChangePowerVideo from '../../components/change-power-video/ChangePowerVideo';

const LandingPage = () => {

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-around"
      flexGrow={1}
      background="rgba(0, 0, 0, .6)"
    >
      <Flex
        flexGrow={1}
        direction="column"
        justify="space-around"
        align="center"
        textAlign="center"
      >
        <Text m={3} variant="h1" as="h1" color="white">
          Change is changing. How prepared is your company?
        </Text>
        <Button
          m={3}
          variant="primary"
          onClick={() => navigate('survey')}
        >
          START THE SURVEY
        </Button>

        <ChangePowerVideo
          m={3}
          // Maintaining aspect ratio of 1.78
          height={['52vw', '40vw', '40vw', '25vw']}
          width={['95vw', '71vw', '71vw', '44vw']}
          maxHeight="400px"
          maxWidth="712px"
        />
      </Flex>
    </Flex>
  );
};
export default LandingPage;
