import { css } from '@emotion/core';
import graphikRegularWOFF from 'url:./assets/fonts/Graphik-Regular-Web.woff';
import graphikRegularWOFF2 from 'url:./assets/fonts/Graphik-Regular-Web.woff2';
import graphikSemiboldWOFF from 'url:./assets/fonts/Graphik-Semibold-Web.woff';
import graphikSemiboldWOFF2 from 'url:./assets/fonts/Graphik-Semibold-Web.woff2';

export default css`
  body {
    font-family: 'Graphik Web', sans-serif;
  }

  @font-face {
    font-family: 'Graphik Web';
    src: url(${graphikRegularWOFF2}) format('woff2') url(${graphikRegularWOFF})
      format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Graphik Web';
    src: url(${graphikSemiboldWOFF2}) format('woff2')
      url(${graphikSemiboldWOFF}) format('woff');
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
  }
`;
