import { configureStore } from '@reduxjs/toolkit';

import regionReducer from './slices/region';
import statementsReducer from './slices/statements';

const store = configureStore({
  reducer: { region: regionReducer, statements: statementsReducer },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
