import React from 'react';
import PropTypes from 'prop-types';
import { Radar } from '@nivo/radar';
import { Flex } from '@adapt-design-system/core';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { useTheme } from 'emotion-theming';

import Labels from './Labels';
import Legend from './Legend';
import PieChart from './PieChart';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: null,
};

const ChangePowerChart = ({ data }) => {
  const { colors } = useTheme();

  return (
    <Flex direction="column" width="100%" align="center">
      <Flex width="100%" height={['40vh', '40vh', '100%']} direction="column">
        <AutoSizer>
          {({ height, width }) => (
            <>
              <Labels height={height} width={width} data={data} />
              <PieChart height={height} width={width} />
              <Flex
                css={{
                  zIndex: 200,
                  position: 'relative',
                }}
              >
                <Radar
                  height={height}
                  width={width}
                  data={data}
                  keys={['you', 'average']}
                  maxValue={10}
                  curve="linearClosed"
                  borderWidth={1}
                  borderColor={{ from: 'color' }}
                  gridLevels={6}
                  gridShape="circular"
                  enableDots={true}
                  dotSize={10}
                  dotColor={{ theme: 'background' }}
                  dotBorderWidth={2}
                  dotBorderColor={{ from: 'color' }}
                  enableDotLabel={true}
                  dotLabel="value"
                  dotLabelYOffset={-8}
                  colors={[colors.primary, colors.text]}
                  fillOpacity={0.25}
                  blendMode="multiply"
                />
              </Flex>
            </>
          )}
        </AutoSizer>
      </Flex>
      <Legend />
    </Flex>
  );
};

ChangePowerChart.propTypes = propTypes;
ChangePowerChart.defaultProps = defaultProps;
export default ChangePowerChart;
